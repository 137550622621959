import * as React from "react"

import Layout from "../components/layout"
import ZnanyLekarz from "../components/ZnanyLekarz"

import joannagalczynska from "../images/joanna_galczynska.jpeg"



const IndexPage = () => {
  return (
    <Layout>
        <div className="site-content-contain">
          <div className="site-content-wrap">
              <div className="site-content container">
                  <div className="content-area ">
                      <main className="site-main">
                          <article className="container dsvy-team-single-style-1 post-12023 dsvy-team-member type-dsvy-team-member status-publish has-post-thumbnail hentry">
                              <div className="dsvy-team-single">
                                  <div className="dsvy-team-single-inner">
                                      <div className="row">
                                          <div className="col-md-5 col-lg-4">
                                              <div className="dsvy-team-left-inner">
                                                  <div className="dsvy-featured-wrapper">
                                                      <img width="800" height="800"
                                                          src={joannagalczynska}
                                                          className="attachment-full size-full wp-post-image"
                                                          alt="Kardiolog Joanna Gałczyńska"
                                                          sizes="(max-width: 800px) 100vw, 800px" />
                                                  </div>
                                                  <div className="dsvy-team-summary">
                                                      <h2 className="dsvy-team-title">lek. Joanna Gałczyńska</h2>
                                                      <h4 className="dsvy-team-designation">Kardiolog</h4>
                                                  </div>
                                                  
                                                  <div className="dsvy-team-member-details-apply">
                                                    <a href="tel:618-610-123">Umów wizytę: <i className="dsvy-base-icon-call-1"></i>618-610-123</a>
                                                  </div>
                                                  
                                                  <ZnanyLekarz 
                                                    doctor="joanna-galczynska" 
                                                    type="big_with_calendar" 
                                                    label="Joanna Gałczyńska - ZnanyLekarz.pl"
                                                    url="https://www.znanylekarz.pl/joanna-galczynska/kardiolog-internista/poznan"
                                                  />
                                                  

                                              </div>
                                          </div>
                                          <div className="col-md-7 col-lg-8">
                                              <div className="dsvy-short-description">
                                                  <h3>O mnie</h3>
                                                  <p>
                                                      Lekarz specjalista kardiolog oraz internista.
                                                      Absolwentka Wydziału Lekarskiego I Uniwersytetu Medycznego w Poznaniu (2004-2010).
                                                      Członkini Polskiego Towarzystwa Kardiologicznego.
                                                  </p>
                                                  <p>
                                                    Pracownik Oddziału Kardiologii z Pododziałem Intensywnej Opieki Kardiologicznej Centrum Medycznego HCP w Poznaniu,
                                                    przy II Klinice Kardiologii Uniwersytetu Medycznego w Poznaniu.
                                                  </p>

                                                  W ramach działania mojego prywatnego gabinetu kardiologicznego wykonuję konsultacje kardiologiczne.
                                                  Dla pacjentów ubezpieczonych recepty na wizycie wystawiane są z należną refundacją NFZ.
                                                  W celu pełnej oceny pacjenta kardiologicznego oferuję Państwu:
                                                  <ul className="dsvy-mdi-checklist">
                                                    <li><i aria-hidden="true" className="mdi mdi-check"></i> badania echokardiograficzne ( “echo serca”)</li>
                                                    <li><i aria-hidden="true" className="mdi mdi-check"></i> elektrokardiograficzne (EKG)</li>
                                                    <li><i aria-hidden="true" className="mdi mdi-check"></i> całodobowe monitorowanie EKG metodą Holtera (24-godzinny Holter EKG) </li>
                                                  </ul>
                                                  W mojej pracy zajmuję się kompleksowym diagnozowaniem i leczeniem pacjentów ze schorzeniami układu sercowo-naczyniowego:
                                                  <ul className="dsvy-mdi-checklist">
                                                    <li><i aria-hidden="true" className="mdi mdi-check"></i> choroba niedokrwienna serca,</li>
                                                    <li><i aria-hidden="true" className="mdi mdi-check"></i> niewydolność serca,</li>
                                                    <li><i aria-hidden="true" className="mdi mdi-check"></i> zawał serca,</li>
                                                    <li><i aria-hidden="true" className="mdi mdi-check"></i> wady serca,</li>
                                                    <li><i aria-hidden="true" className="mdi mdi-check"></i> zabużenia rytmu serca, arytmie,</li>
                                                    <li><i aria-hidden="true" className="mdi mdi-check"></i> nadciśnienie tętnicze,</li>
                                                    <li><i aria-hidden="true" className="mdi mdi-check"></i> choroby zapalne serca,</li>
                                                    <li><i aria-hidden="true" className="mdi mdi-check"></i> żylna choroba zakrzepowo-zatorowa.</li>

                                                  </ul>

                                              </div>

                                              <div className="dsvy-short-description">
                                                  <h3>Otrzymają Państwo</h3>

                                                  <div className="dsvy-element-posts-wrapper row multi-columns-row">

                                                    <article className="dsvy-ele dsvy-ele-miconheading dsvy-miconheading-style-9 col-md-4">
                                                        <div className="dsvy-ihbox dsvy-ihbox-style-9">
                                                            <div className="dsvy-ihbox-box">
                                                                <div className="dsvy-ihbox-icon">
                                                                    <div className="dsvy-ihbox-icon-wrapper dsvy-ihbox-icon-type-icon">
                                                                        <i className="dsvy-cardioly-icon dsvy-cardioly-icon-doctor"></i>
                                                                    </div>
                                                                </div>
                                                                <div className="dsvy-ihbox-contents">
                                                                  <h2 className="dsvy-element-title">Poradę lekarska</h2>
                                                                </div>
                                                                
                                                            </div>
                                                        </div>
                                                    </article>
                                                    <article className="dsvy-ele dsvy-ele-miconheading dsvy-miconheading-style-9 col-md-4   ">
                                                        <div className="dsvy-ihbox dsvy-ihbox-style-9">
                                                            <div className="dsvy-ihbox-box">
                                                                <div className="dsvy-ihbox-icon">
                                                                    <div  className="dsvy-ihbox-icon-wrapper dsvy-ihbox-icon-type-icon">
                                                                        <i className="dsvy-cardioly-icon dsvy-cardioly-icon-heart"></i>
                                                                    </div>
                                                                </div>
                                                                <div className="dsvy-ihbox-contents">
                                                                    <h2 className="dsvy-element-title">Badanie echokardiograficzne</h2>
                                                                </div>
                                                                
                                                            </div>
                                                        </div>
                                                    </article>
                                                    <article className="dsvy-ele dsvy-ele-miconheading dsvy-miconheading-style-9 col-md-4   ">
                                                        <div className="dsvy-ihbox dsvy-ihbox-style-9">
                                                            <div className="dsvy-ihbox-box">
                                                                <div className="dsvy-ihbox-icon">
                                                                    <div className="dsvy-ihbox-icon-wrapper dsvy-ihbox-icon-type-icon">
                                                                        <i className="dsvy-cardioly-icon dsvy-cardioly-icon-cardiogram"></i>
                                                                    </div>
                                                                </div>
                                                                <div className="dsvy-ihbox-contents">
                                                                    <h2 className="dsvy-element-title">Badanie EKG</h2>
                                                                </div>
                                                                
                                                            </div>
                                                        </div>
                                                    </article>
                                                  </div>
                                              </div>

                                           
                                              <div className="dsvy-entry-content">
                                                  <div data-elementor-type="wp-post"
                                                      data-elementor-id="12023"
                                                      className="elementor elementor-12023">
                                                      <div className="elementor-inner">
                                                          <div
                                                              className="elementor-section-wrap">
                                                             
                                                  
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </article>
                      </main>
                  </div>
              </div>
          </div>

      </div>

    </Layout>          
  )
}

export default IndexPage
